body {
  font-family: 'Chivo', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.helpText {
  position: fixed;
  bottom: 30px;
  right: 85px;
  background: #FFF;
  z-index: 1000;
  text-align: right;
  font-weight: 500;
  padding: 5px 10px;
  box-shadow: 0px -10px 30px -5px rgb(0 0 0 / 20%);
  border-radius: 5px;
  font-size: 13px;
}

.helpText:after {
  content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 5px solid transparent;
	border-left-color: #fff;
	border-right: 0;
	margin-top: -5px;
	margin-right: -5px;
}
